@font-face {
  font-family: TL1;
  src: url(/public/fonts/TL1.ttf);
}
@font-face {
  font-family: TL2;
  src: url(/public/fonts/TL2.ttf);
}
@font-face {
  font-family: TL3;
  src: url(/public/fonts/TL3.ttf);
}
@font-face {
  font-family: TL4;
  src: url(/public/fonts/TL4.ttf);
}
@font-face {
  font-family: TL5;
  src: url(/public/fonts/TL5.ttf);
}
@font-face {
  font-family: TL6;
  src: url(/public/fonts/TL6.ttf);
}
@font-face {
  font-family: TL7;
  src: url(/public/fonts/TL7.ttf);
}
@font-face {
  font-family: TL8;
  src: url(/public/fonts/TL8.ttf);
}
@font-face {
  font-family: TLE1;
  src: url(/public/fonts/TLE1.ttf);
}
@font-face {
  font-family: TLE2;
  src: url(/public/fonts/TLE2.ttf);
}
@font-face {
  font-family: TLE3;
  src: url(/public/fonts/TLE3.otf);
}
@font-face {
  font-family: TLE4;
  src: url(/public/fonts/TLE4.ttf);
}
@font-face {
  font-family: TLE5;
  src: url(/public/fonts/TLE5.ttf);
}
@font-face {
  font-family: TLE6;
  src: url(/public/fonts/TLE6.ttf);
}
@font-face {
  font-family: TLE7;
  src: url(/public/fonts/TLE7.otf);
}
@font-face {
  font-family: TLE8;
  src: url(/public/fonts/TLE8.ttf);
}
@font-face {
  font-family: TLE9;
  src: url(/public/fonts/TLE9.ttf);
}
@font-face {
  font-family: TLE10;
  src: url(/public/fonts/TLE10.ttf);
}
@font-face {
  font-family: TLE11;
  src: url(/public/fonts/TLE11.ttf);
}
@font-face {
  font-family: TLE12;
  src: url(/public/fonts/TLE12.ttf);
}
@font-face {
  font-family: TLE13;
  src: url(/public/fonts/TLE13.ttf);
}
.backdrop {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 10;

}
.alert-box {
	padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    display: none;
}