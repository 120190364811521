.label-tab {
  border-radius: 100px;
  padding: 20px;
  width: 480px; /*280*/
  height: 180px;
  /*Flex Box*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*Flex Box*/
}

.label-tab-l1-sicon {
  /*border: 2px solid #000;*/
  width: 100%;
  /*top: 17%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
}
.label-tab-l1-cicon {
  /*border: 1px solid red;*/
  width: 70%;
  left: 25%;
  /*top: 18%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
}

.label-tab-l2-cicon {
  /*border: 1px solid red;*/
  width: 70%;
  left: 25%;
  top: 20%;
    /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}
.label-tab-l3-cicon {
  /*border: 1px solid #000;*/
  bottom: 20%;
  width: 70%;
  left: 25%;
    /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}
.label-tab-l2-sicon {
  /*border: 1px solid red;*/
  width: 90%;
  top: 15%;
    /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-tab-l3-sicon {
  /*border: 1px solid #000;*/
  bottom: 15%;
  width: 90%;
    /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/

}
.iconleft_tab {
  /*border: 2px solid #000;*/
  left: 2%;
  height: 130px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*display: none;*/
}
.label-tab-brand {
  /*border: 2px solid #000;*/
  transform: rotate(270deg);
  font-size: 9pt;
  text-align: center;
  position: absolute;
  left: 0%;
  font-family: TL3;
}

