.accordion-button {
  background-color: white !important;
  color: blue !important;
  font-size: 9pt;
  font-style: normal;
   border: none;
}
.item-qty {
    margin-right: 10px;
    text-align: center;
    width: 45px !important;
    background: #fff;
    background-clip: padding-box;
    border: 2px solid #93dff1;
    border-radius: 6px;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 14px;
    height: 40px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    box-sizing: border-box;

}

.details-qty {
    color: #333;
    list-style: none none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    margin-top: 10px;
    float: left;
    text-align: right;
}
/*
.zoom:hover {
  transform: scale(2.5);
}
*/
.cartHeader {
    border-bottom: 1px solid #29bce1;
    margin-bottom: 15px;
}

.update-cart-item {
    background: #fff;
    border: 2px solid #29bce1;
    color: #29bce1;
    cursor: pointer;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 700;
    padding: 7px 15px 7px 15px;
    font-size: 0.9rem !important;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
     margin: 0;
    width: 100px !important;
    height: 40px !important;
    border-radius: 6px;
}
.actionLink {
    float: left;
    border-radius: 3px;
    background: #fff;
    border: 2px solid #29bce1;
    color: #29bce1;
    cursor: pointer;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 700;
    padding: 7px 15px 7px 15px;
    font-size: 0.9rem !important;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    text-align: right;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
     margin: 0;
}
.actionLink:focus,
.actionLink:active,
.actionLink:hover {
  background: #29bce1;
  border: 2px solid #29bce1;
  color: #fff;
}