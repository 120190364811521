.single-theme {
  width: 125px;
  height: 80px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  margin-right: 5px;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: top;
  text-align: center;
  font-weight: 600;
}
.single-theme:hover {
  border: 1px solid #29bce1;
}
.single-theme.selected {
  border: 1px solid #29bce1;
}
