.label-ec {
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
  border: 1px solid #000000;
  width: 180px; /*280*/
  height: 200px;
}

.label-ec-l1-sicon {
/*  border: 2px solid #000;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  top: 5%;
}
.label-ec-l1-cicon {
 /*border: 2px solid #000;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  top: 5%;
}

.label-ec-l2-cicon {
 /* border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
  /*Flex Box*/
  width: 100%;
  top: 2%;
}
.label-ec-l2-sicon {
  /*border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
  /*Flex Box*/
  width: 100%;
  top: 2%;
}
.label-ec-l3-cicon {
  /*border: 1px solid green ;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:25%;
  justify-content: center;
  /*Flex Box*/
  width: 100%;
  top: 27%;
}
.label-ec-l3-sicon {
 /* border: 1px solid green ;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:25%;
  justify-content: center;
  /*Flex Box*/
  width: 100%;
  top: 27%;
}
.iconleft_ec {
 /* border: 2px solid #000;*/
  bottom: 5%;
  left: 30%;
  height: 75px;
  position: absolute;
  /*display: none;*/
}
.iconright_ec {
  /*border: 2px solid #000;*/
  bottom: 5%;
  left: 30%;
  height: 75px;
  position: absolute;
}

.label-ec-l1-cicon-right {
  /*border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
  /*Flex Box*/
  width: 100%;
  top: 2%;
}
.label-ec-l2-cicon-right {
  /*border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
  /*Flex Box*/
  width: 100%;
  top: 2%;
}
.label-ec-l3-cicon-right {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:25%;
  justify-content: center;
  /*Flex Box*/
  width: 100%;
  top: 27%;
}
.label-ec-brand {
  /*border: 1px solid #000;*/
  top:40%;
  bottom: 35%;
  left: 7%;
  margin: 0%;
}
.label-ec-brand-dir {
  top:40%;
  bottom: 35%;
  right: 7%;
  margin: 0%;
}
