.input-text {
  background: #fff;
  background-clip: padding-box;
  border: 2px solid #93dff1;
  border-radius: 6px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857143;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
}
.field {
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: inline-block;
  margin-top: 0.5rem;
  font-size: small;
}
label.required:after {
  content: '*';
  color: red;
  padding-left: 5px;
}


