.label-ftlb {
  width: 290px; /*280*/
  height: 130px;
    /*Flex Box*/
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-ftlb-l1-sicon {
  /*border: 2px solid #000;*/
  width: 90%;
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-ftlb-l2-sicon {
  /*border: 1px solid red;*/
  width: 90%;

}

.label-ftlb-l3-sicon {
  /* border: 1px solid #000;*/
  width: 90%;
}

