button.nav-link.active {
    color: white !important;
    background: #29bce1 !important;
    border-radius: 2px;
}
button.nav-link.hover {
    color: white;
    background: #29bce1 !important;
    border-radius: 0px;
}
button.nav-link {
    color: #666 !important;
    background: #f0f0f0 !important;
    border-radius: 0px;

}
.nav-tabs {
    background: #f2f2f2 ;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important;
    font-weight: 100 !important;

}
