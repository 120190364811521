.price {
    font-weight: 400;
    font-size: 21px;
    color: #29bce1;
}
.product-item-name{
    color: #333;
    text-decoration: none;
    font-weight: 600;
}
a.action.primary {
    display: inline-block;
    text-decoration: none;
    border: 2px solid #29bce1;
    color: #29bce1;
    background: #fff;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 700;
    padding: 5px 10px;
    font-size: 1rem;
    box-sizing: border-box;
    vertical-align: middle;
    transition: color .3s ease,background-color .3s ease;
    white-space: nowrap;
}
.action.primary:hover {
    background: #29bce1;
    border: 2px solid #29bce1;
    color: #fff;
}