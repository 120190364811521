.label-desplb-EAESPM {
  padding: 20px;
  width: 280px; /*280*/
  height: 160px;
    /*Flex Box*/
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-desplb-l1-sicon-EAESPM {
 /* border: 2px solid #000;*/
  width: 100%;
  /*padding-top: 15%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-desplb-l2-sicon-EAESPM {
 /* border: 1px solid red;*/
  width: 100%;
 /* top: 10%;
  padding-top: 10%;
  left: 0%;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-desplb-l3-sicon-EAESPM{
 /* border: 1px solid #000;*/
  width: 100%;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

}

.label-desplb-EAESPR {
/*border: 2px solid #000;*/
  padding: 0px;
  width: 280px; /*280*/
  height: 280px;
  /*Flex Box*/
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-desplbrd-l1-sicon-EAESPR {
 /* border: 2px solid #000;*/
  width: 100%;
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}


.label-desplbrd-l2-sicon-EAESPR {
  /*border: 1px solid red;*/
  width: 100%;
  top: 10%;
  /*padding-top: 10%;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-desplbrd-l3-sicon-EAESPR {
 /* border: 1px solid #000;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10%;
}

.label-desplb-EAESPG {
  padding: 20px;
  padding-bottom: 40px;
  width: 405px; /*280*/
  height: 160px;
    /*Flex Box*/
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}

.label-desplbgrd-l1-sicon-EAESPG {
 /* border: 2px solid #000;*/
  width: 100%;
  /*padding-top: 15%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
}


.label-desplbgrd-l2-sicon-EAESPG {
 /* border: 1px solid red;*/
  width: 100%;
 /*top: 10%;
  padding-top: 10%;
  left: 0%;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-desplbgrd-l3-sicon-EAESPG {
 /* border: 1px solid #000;*/
  width: 100%;
  /*bottom: 10%;
  padding: 0px;
  left: 0%;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

}


