.label-bike {
  border-radius: 25px;
  padding: 20px;
  width: 400px; /*280*/
  height: 90px;
}

.label-bike-l1-sicon {
  /*border: 2px solid #000;*/
   /*Flex Box to help align text to vertical middle*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
  width: 90%;
  top: 0%;
}
.label-bike-l1-cicon {
  /*border: 1px solid black;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
  width: 75%;
  left: 22%;
}

.label-bike-l2-cicon {
  /*border: 1px solid red;*/
     /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
  top: 0%;
  width: 70%;
  left: 80px;
}
.label-bike-l2-sicon {
  /*border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  top: 0%;
  /*Flex Box*/
  width: 88%;
  left: 8%;
}
.label-bike-l3-cicon {
  /*border: 1px solid green;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:50%;
  /*Flex Box*/
  width: 70%;
  bottom: 0;
  left: 80px;
}
.label-bike-l3-sicon {
  /*border: 1px solid green ;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
  width: 88%;
  bottom: 0;
  left: 8%;
}
.iconleft_bike {
  /*border: 2px solid #000;*/
  bottom: 3%;
  left: 3%;
  height: 75px;
  /*display: none;*/
}
.iconright_bike {
  /*border: 2px solid #000;*/
  bottom: 3%;
  right: 3%;
  height: 75px;
}

.label-bike-l1-cicon-right {
  /* border: 1px solid red;*/
  width: 70%;
  height: 100%;
  padding-left: 0.5em;
  right: 30%;
  top: 0%; /*18*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/

}
.label-bike-l2-cicon-right {
   /*border: 1px solid red;*/
  width: 70%;
  top: 0;
  right: 80px;
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
}
.label-bike-l3-cicon-right {
  /*border: 1px solid #000;*/
  width: 70%;
  bottom: 0;
  right: 80px;
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
}
.label-brand {
  top:30%;
  bottom: 43%;
  margin: 0%;
}
.label-brand-dir {
  top:30%;
  bottom: 43%;
  margin: 0%;
}
