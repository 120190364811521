.label-reg {
  border-radius: 25px;
  padding: 20px;
  width: 240px; /*280*/
  height: 60px;
}

.label-reg-l1-sicon {
  /*border: 2px solid #000;*/

  width: 85%;
  /*top: 17%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
}
.label-reg-l1-cicon {
  /*border: 1px solid red;*/
  width: 70%;
  left: 25%;
  /*top: 18%;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
}

.label-reg-l2-cicon {
  /*border: 1px solid red;*/
  top: 0%;
  width: 70%;
  left: 55px;
}
.label-reg-l2-sicon {
  /*border: 1px solid red;*/
  width: 88%;
  top: 0;
  left: 8%;
}
.label-reg-l3-cicon {
  /*border: 1px solid #000;*/
  width: 70%;
  bottom: 0;
  left: 55px;
}
.label-reg-l3-sicon {
  /* border: 1px solid #000;*/
  width: 88%;
  bottom: 0;
  left: 8%;

}
.iconleft_reg {
  /*border: 2px solid #000;*/
  bottom: 2%;
  left: 6%;
  height: 55px;
  /*display: none;*/
}
.iconright_reg {
  /*border: 2px solid #000;*/
  bottom: 2%;
  right: 5%;
  height: 55px;
  /*-webkit-transform: scaleX(-1); para espelhar a imagem
         transform: scaleX(-1); para espelhar a imagem
         display: none;*/
}


.label-reg-l1-cicon-right {
  /* border: 1px solid red;*/
  width: 70%;
  height: 100%;
  padding-left: 0.5em;
  right: 27%;
  top: 18%; /*17*/

}
.label-reg-l2-cicon-right {
   /*border: 1px solid red;*/
  width: 70%;
  top: 0;
  right: 65px;

}
.label-reg-l3-cicon-right {
  /*border: 1px solid #000;*/
  width: 70%;
  bottom: 0;
  right: 65px;

}
