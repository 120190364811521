.label-bike2 {
  border-radius: 25px;
  padding: 20px;
  width: 500px;
  height: 110px;
}

.label-bike2-l1-sicon {
  /*border: 2px solid #000;*/
   /*Flex Box to help align text to vertical middle*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
  width: 100%;
  top: 0%;
}
.label-bike2-l1-cicon {
  /*border: 1px solid black;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
  width: 75%;
  left: 22%;
}

.label-bike2-l2-cicon {
  /*border: 1px solid red;*/
     /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
  top: 0%;
  width: 80%;
  left: 90px;
}
.label-bike2-l2-sicon {
  /*border: 1px solid red;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  top: 0%;
  /*Flex Box*/
  width: 98%;
  left: 0%;
}
.label-bike2-l3-cicon {
  /*border: 1px solid green;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:50%;
  /*Flex Box*/
  width: 80%;
  bottom: 0;
  left: 90px;
}
.label-bike2-l3-sicon {
  /*border: 1px solid green ;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
  width: 98%;
  bottom: 0;
  left: 0%;
}
.iconleft-bike2 {
  /*border: 2px solid #000;*/
  bottom: 3%;
  left: 2%;
  height: 100px;
  /*display: none;*/
}

.label-brand {
  top:30%;
  bottom: 43%;
  margin: 0%;
}
.label-brand-dir {
  top:30%;
  bottom: 43%;
  margin: 0%;
}
