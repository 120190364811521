.navbar-tiketa {
    background: #93dff1;
}
.navbar-tiketa .navbar-tiketa-brand {
    color: white !important;
}
.navbar-tiketa-brand {
    cursor: pointer;
}
@media print {
   .navDireita{
      display: none;
   }
}