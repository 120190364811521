.label-lcm {
  /*border-radius: 25px;
  border: 1px solid #000;
  background: white;
    margin: 0;
  /*top: 30%;
  position: relative;*/
  border-radius: 25px;
  padding: 20px;
  width: 220px; /*280*/
  height: 15px;

}

.label-lcm-brand {
  /*border: 2px solid #000;
  transform: rotate(270deg);
  font-size: 6pt;
  text-align: center;
  position: absolute;
  left: 0%;
  font-family: TL3;*/
  bottom : 32%;
}
.label-lcm-brand-dir {
  bottom : 32%;
}
.iconleft_lcm {
  /*border: 2px solid #000;*/
  bottom: 2%;
  left: 6%;
  height: 35px;
  /*display: none;*/
}
.iconright_lcm {
  /*border: 2px solid #000;*/
  bottom: 2%;
  right: 5%;
  height: 35px;
}
.label-lcm-l1-sicon {
  /*border: 2px solid #000;*/
  width: 90%;
  top: 0%;
}
.label-lcm-l1-cicon {
  /*border: 1px solid red;*/
  width: 80%;
  left: 17%;
  top: 0%;
}
.label-lcm-l1-cicon-right {
  /*border: 1px solid red;*/
  width: 80%;
  height: 100%;
  padding-left: 0.5em;
  right: 20%;
  top: 0%; /*17*/

}