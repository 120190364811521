.input-text2 {
  background: #fff;
  background-clip: padding-box;
  border: 2px solid #93dff1;
  border-radius: 6px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857143;
  padding: 0 9px;
  vertical-align: baseline;
}
.field {
  border: 2px solid #93dff1;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: small;
}
.orderLink {
  text-decoration: underline !important;
}
.orderLink:hover {
  cursor: pointer;
}
.sortable:hover {
  cursor: pointer;
}