@media print {
    .container {
        width: auto;
    }
    .productImage {
        max-width: 25%;
    }
}
@media print {
   .printButton{
      display: none;
   }
   .headerRow{
           font-weight: 300;
           font-size: 0.9rem;
   }
   .printText  {
       font-size: 10pt;
   }

}
@media screen {
    .productImage {
        max-width: 35%;
    }
}
.headerRow1 {
    font-weight: 700;
    font-size: 2.4rem;
    margin-top: 2rem;
    line-height: 1.1;
    margin-bottom: 2rem;
    padding-bottom: 20px;
}
.titleRow{
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 1.1;
    margin-bottom: 2rem;
    padding-bottom: 20px;

}
