.label-ag {
  border-radius: 25px;
  padding: 0px;
  width: 250px ; /*280*/
  height: 125px;
}
.iconleft_ag {
  /*border: 2px solid #000;*/
  bottom: 12%;
  height: 75px;
  /*display: none;*/
}
.iconright_ag {
  /*border: 2px solid #000;*/
  bottom: 12%;
  right: 0%;
  height: 75px;
}
.label-brand_ag {
  /*border: 2px solid #000;*/
  font-size: 6pt;
  text-align: center;
  position: absolute;
  bottom: 1%;
  left: 7%;
  font-family: TL3;
}
.label-brand-dir_ag {
  /*border: 2px solid #000;*/
  font-size: 6pt;
  text-align: center;
  position: absolute;
  bottom:1%;
  right: 7%;
  font-family: TL3;
}
.label-ag-l1-sicon {
 /* border: 2px solid #000;*/
   /*Flex Box to help align text to vertical middle*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/
  width: 100%;
  top: 0%;
}
.label-ag-l1-cicon {
  /*border: 1px solid black;*/
  /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
  width: 70%;
  left: 25%;
}

.label-ag-l2-cicon {
  /*border: 1px solid red;*/
     /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
  top: 7%;
  width: 70%;
  left: 25%;
}
.label-ag-l3-cicon {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:50%;
  /*Flex Box*/
  width: 70%;
  bottom: 7%;
  left: 25%;
}
.label-ag-l2-sicon {
  /*border: 1px solid red;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  top: 7%;
  width: 88%;
  left: 6%;
}
.label-ag-l3-sicon {
  /*border: 1px solid #000;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  width: 88%;
  bottom: 7%;
  left: 6%;
}
.label-ag-l1-cicon-right {
  /*border: 1px solid red;*/
  width: 70%;
  height: 100%;
  padding-left: 0.5em;
  right: 30%;
  top: 0%; /*18*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /*Flex Box*/

}
.label-ag-l2-cicon-right {
  /* border: 1px solid red;*/
  width: 73%;
  top: 7%;
  right: 27%;
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
}
.label-ag-l3-cicon-right {
  /*border: 1px solid #000;*/
  width: 73%;
  bottom: 7%;
  right: 27%;
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:50%;
  justify-content: center;
  /*Flex Box*/
}
.label-ag-l4-sicon {
  /*border: 1px solid red;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33%;
  width: 100%;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l5-sicon {
  /*border: 1px solid #000;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:33%;
  justify-content: center;
  width: 100%;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l6-sicon {
  /*border: 1px solid red;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height:33%;
  justify-content: center;
  width: 100%;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l4-cicon {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  /*Flex Box*/
  width: 70%;
  left: 25%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l5-cicon {
 /* border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  /*Flex Box*/
  width: 70%;
  left: 25%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l6-cicon {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  width: 70%;
  left: 25%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l4-cicon-right {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  /*Flex Box*/
  width: 70%;
  right: 0%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l5-cicon-right {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  /*Flex Box*/
  width: 70%;
  right: 0%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}
.label-ag-l6-cicon-right {
  /*border: 1px solid #000;*/
   /*Flex Box*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height:33%;
  width: 70%;
  right: 0%;
  position: relative;
  font-size: 18pt;
  white-space: nowrap;
}