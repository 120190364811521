.label-lc {
  border-radius: 25px;
  padding: 20px;
  width: 290px; /*280*/
  height: 15px;
}

.label-lc-brand {
  bottom : 32%;
}
.label-lc-brand-dir {
  bottom : 32%;
}
.iconleft_lc {
  bottom: 2%;
  left: 6%;
  height: 35px;
}
.iconright_lc {
  bottom: 2%;
  right: 5%;
  height: 35px;
}
.label-lc-l1-sicon {
  /*border: 2px solid #000;*/
  width: 90%;
  top: 0%;
}
.label-lc-l1-cicon {
  /*border: 1px solid red;*/
  width: 80%;
  left: 17%;
  top: 0%;
}
.label-lc-l1-cicon-right {
  /*border: 1px solid red;*/
  width: 80%;
  height: 100%;
  padding-left: 0.5em;
  right: 20%;
  top: 0%; /*17*/
}