.label-rdmin {
  border-radius: 200px;
  padding: 20px;
  width: 200px; /*280*/
  height: 200px;
}
.label-rdmin-brand {
  /*border: 2px solid #000;*/
  transform: rotate(270deg);
  font-size: 6pt;
  text-align: center;
  position: absolute;
  left: 6%;
  top:33%;
  font-family: TL3;
}
.circTxt {
  /*transform: translateX(40px);*/
  z-index: 99;
  font-size: 12px;
  font-weight: 500;
}
.label-rdmin-l1-sicon {
 /* border: 2px solid #000;*/
  width: 80%;
  rotate: 0deg;
}
.label-rdmin-l2-sicon {
 /* border: 1px solid red;*/
  width: 80%;
  rotate: 0deg;
  height: 100%;
}

.label-rdmin-l1-cicon {
  /*border: 1px solid red;*/
  width: 70%;
  left: 25%;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 0%;
  justify-content: center;
  /*Flex Box*/
}

.idCircular {
  position: absolute;
  transform-origin: 0px 100px;
}



.label-rdmin-l2-cicon {
  border: 1px solid red;
  top: 0%;
  width: 70%;
  left: 55px;
}

.label-rdmin-l3-cicon {
  /*border: 1px solid #000;*/
  width: 70%;
  bottom: 0;
  left: 55px;
}
.label-rdmin-l3-sicon {
 /* border: 1px solid #000;*/
  width: 100%;
  top: 34%;
  left: -2%;
  height: 100%;
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  display: inline-block;
  white-space: nowrap;

}
.iconleft_rdmin {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  top: 18%;
  justify-content: center;
  left: 32%;
  height: 60%;
}
.iconright_rdmin {
  /*border: 2px solid #000;*/
  bottom: 2%;
  right: 5%;
  height: 55px;
  /*-webkit-transform: scaleX(-1); para espelhar a imagem
         transform: scaleX(-1); para espelhar a imagem
         display: none;*/
}


.label-rdmin-l1-cicon-right {
  /* border: 1px solid red;*/
  width: 70%;
  height: 100%;
  padding-left: 0.5em;
  right: 27%;
  top: 18%; /*17*/

}
.label-rdmin-l2-cicon-right {
   /*border: 1px solid red;*/
  width: 70%;
  top: 0;
  right: 65px;

}
.label-rdmin-l3-cicon-right {
  /*border: 1px solid #000;*/
  width: 70%;
  bottom: 0;
  right: 65px;

}
