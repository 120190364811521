.headerRow {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 2rem;
    line-height: 1.1;
    margin-bottom: 2rem;
    padding-bottom: 20px;
}

.tableHeader {
    border-bottom: 1px solid #29bce1;
    margin-bottom: 15px;
}
.productImage {
    max-width: 25%;
}
.productItemDescription{
    margin-bottom: 0px;
}
.productTitleCheckout {
    font-weight: 700;
    line-height: 1.1;
    font-size: 14px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.productDescriptionHeader{
    font-size: 14px;
    font-weight: 700;
    margin-right: 0.5rem;
}
.productCheckoutDescription{
    font-size: 14px;
}