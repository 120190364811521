.subjectSelector {
    padding: 10px;
    padding-top: 0;
    border: 2px solid #93dff1;
    border-radius: 6px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
}
.subjectDiv {
    font-size: 14px;
    border: 1px solid #29bce1;
    padding: 5px 10px;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    min-width: 120px;
    transition: all .3s;
}