.label {
  border: 1px solid #000;
  background: white;
  margin: 0;
  position: relative;
}

.label-brand {
  /*border: 2px solid #000;*/
  transform: rotate(270deg);
  font-size: 6pt;
  text-align: center;
  position: absolute;
  left: 0%;
  font-family: TL3;
}
.label-brand-dir {
  /*border: 2px solid #000;*/
  transform: rotate(90deg);
  font-size: 6pt;
  text-align: center;
  position: absolute;
  right: 0%;
  font-family: TL3;
}
.label-l1-sicon {
  /*border: 2px solid #000;*/
  height: 100%;
  /*padding-left: 0.5em;*/
  text-align: center;
  margin: 0;
  font-size: 18pt;
  position: absolute;
  bottom: 0%;
  font-family: Calibri;
  display: inline-block;
  white-space: nowrap;
}
.label-l1-cicon {
  /*border: 1px solid red;*/
  height: 100%;
  padding-left: 0.5em;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  margin: 0;
  font-size: 18pt;
  display: inline-block;
  white-space: nowrap;
}

.label-l2-cicon {
  /*border: 1px solid red;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.label-l2-sicon {
  /*border: 1px solid red;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.label-l3-cicon {
  /*border: 1px solid #000;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.label-l3-sicon {
  /* border: 1px solid #000;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.iconleft {
  /*border: 2px solid #000;*/
  position: absolute;
  object-fit: contain;
  /*display: none;*/
}
.iconright {
  /*border: 2px solid #000;*/
  position: absolute;
  object-fit: contain;
  /*-webkit-transform: scaleX(-1); para espelhar a imagem 
         transform: scaleX(-1); para espelhar a imagem 
         display: none;*/
}
.labelSimulationCard {
  border: 0px !important;
  position: sticky;

  /*top: 30%;*/
}
.labelSimulationCard1 {
  border: 0px !important;
  position: sticky;
  top: 30%;
}

.label-l1-cicon-right {
  /* border: 1px solid red;*/
  padding-left: 0.5em;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  margin: 0;
  font-size: 18pt;
  display: inline-block;
  white-space: nowrap;
}
.label-l2-cicon-right {
   /*border: 1px solid red;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.label-l3-cicon-right {
  /*border: 1px solid #000;*/
  padding-left: 0.5em;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-size: 16pt;
  display: inline-block;
  white-space: nowrap;
}
.columnKit {
  /*border: black 1px solid;*/
  margin: 10px 10px;
  display: flex;
  position: relative;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  /*height:50%;*/
  justify-content: center;
}
.rowKit {
 /* border: black 1px solid;*/
  display: -webkit-flex;
  display: flex;
  align-items: center;
  /*height:40%;*/
  justify-content: center;
}
.imgKit {
 /* border: black 1px solid;*/
  max-width:80%;
}

#productImage {
  width: 90%;
  height: auto;
}